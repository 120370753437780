@charset "utf-8";

$base-font-family: "Courier New", monospace, !default;
$base-font-size:   12pt !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$large-font-size:  $base-font-size * 1.125 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

// Dark Theme
// $text-color:       #fff !default;
// $background-color: #202222 !default;

// Light Theme
$text-color:       #202222 !default;
$background-color: #fff !default;

$brand-color:      #ec5c29 !default;
$message-color:    #339900 !default;
$warning-color:    #ffcc00 !default;
$error-color:      #cc3300 !default;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$narrow-content-width: 800px !default;
$medium-content-width: 1200px !default;
$wide-content-width:   1600px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;
$on-narrow:        1300px !default;

@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@mixin relative-font-size($ratio) {
    font-size: $base-font-size * $ratio;
}

@import
    "39alpha/base",
    "39alpha/layout",
    "39alpha/syntax-highlighting";
