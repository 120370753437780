body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}

body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  color: $text-color;
  background-color: $background-color;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  @include media-query($on-palm) {
    @include relative-font-size(0.825);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 2;
}

main {
  display: block;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

figure>img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}

ul,
ol {
  margin-left: $spacing-unit;
}

li {

  >ul,
  >ol {
    margin-bottom: 0;
  }
}

ol {
  li {
    padding-bottom: 1em;
    margin-left: 2em;
  }
}

dl {
  margin-left: 2 * $spacing-unit;

  >dt {
    margin-top: $spacing-unit;
    font-weight: 2 * $base-font-weight;
  }

  >dd {
    margin-left: $spacing-unit;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 2 * $base-font-weight;
  margin-top: $spacing-unit;
}

a {
  color: $brand-color;
  text-decoration: none;

  &:hover {
    color: $brand-color;
    text-decoration: underline;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}

blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color-light;
  padding-left: $spacing-unit / 2;
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}

pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  >code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}

@mixin wrapper($width) {
  max-width: -webkit-calc(#{$width} - (#{$spacing-unit} * 2));
  max-width: calc(#{$width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$width} - (#{$spacing-unit}));
    max-width: calc(#{$width} - (#{$spacing-unit}));
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }
}

.wrapper {
  &--narrow {
    @include wrapper($narrow-content-width);
  }

  &--medium {
    @include wrapper($medium-content-width);
  }

  &--wide {
    @include wrapper($wide-content-width);
  }
}

%clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.svg-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: #{$grey-color};
  padding-right: 5px;
  vertical-align: text-top;
}

.social-media-list {
  li+li {
    padding-top: 5px;
  }
}

table {
  margin-bottom: $spacing-unit;
  width: 100%;
  text-align: $table-text-align;
  color: lighten($text-color, 18%);
  border-collapse: collapse;
  border: 1px solid $grey-color-light;

  tr {
    &:nth-child(even) {
      background-color: lighten($background-color, 5%);
    }
  }

  th,
  td {
    padding: ($spacing-unit / 3) ($spacing-unit / 2);
  }

  th {
    background-color: lighten($background-color, 15%);
    border: 1px solid darken($grey-color-light, 4%);
    border-bottom-color: darken($grey-color-light, 12%);
  }

  td {
    border: 1px solid $grey-color-light;
  }
}

.hidden {
  display: none;
}

iframe {
  width: 100%;
  border: 0;
}

.emphasize {
  color: $brand-color;
}

.footnotes {
  ol {
    padding-top: 0.5 * $spacing-unit;
    margin-bottom: 0;

    li {
      padding: 0;
    }
  }
}